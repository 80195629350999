import '../styles/innovation.scss'

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { useLayoutEffect, useState } from 'react'

import IconBlock from '../components/IconBlock'
import ImageHero from '../components/ImageHero'
import Parallax from '../components/Parallax'
import SEO from '../components/SEO'
import windowSize from '../hooks/windowSize'
import Layout from '../layouts/Layout'

const InnovationPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "innovation-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      serviceImage: file(relativePath: { eq: "powder-stick.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 840, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      serviceImageMobile: file(
        relativePath: { eq: "powder-stick-mobile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 840, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cbdThumbnail: file(relativePath: { eq: "fda.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      birdThumbnail: file(relativePath: { eq: "bird-thumbnail.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pillThumbnail: file(relativePath: { eq: "pill-thumbnail.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      datoCmsInnovationPage {
        heroHeadline
        heroBody
        heroBodyNode {
          childMarkdownRemark {
            html
          }
        }
        innovationHeadline
        innovationBodyNode {
          childMarkdownRemark {
            html
          }
        }
        discoveryHeadline
        discoveryBodyNode {
          childMarkdownRemark {
            html
          }
        }
        discoveryList {
          id
          title
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          icon {
            url
          }
        }
        researchHeadline
        researchBodyNode {
          childMarkdownRemark {
            html
          }
        }
        researchList {
          id
          title
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          icon {
            url
          }
        }
        developmentHeadline
        developmentBodyNode {
          childMarkdownRemark {
            html
          }
        }
        developmentList {
          id
          title
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          icon {
            url
          }
        }
        scienceHeadline
        scienceBodyNode {
          childMarkdownRemark {
            html
          }
        }
        serviceHeadline
        serviceBodyNode {
          childMarkdownRemark {
            html
          }
        }
      }

      allDatoCmsInsightPost(
        sort: { fields: publishDate, order: DESC }
        limit: 3
      ) {
        nodes {
          id
          title
          thumbnail {
            fluid(
              maxWidth: 420
              imgixParams: { ar: "16:9", fit: "crop" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `)

  const dato = data.datoCmsInnovationPage
  const getHtml = node => ({
    __html: node.childMarkdownRemark.html,
  })

  const { innerWidth } = windowSize()
  const [windowWidth, setWindowWidth] = useState(null)
  useLayoutEffect(() => {
    setWindowWidth(innerWidth)
  }, [innerWidth])
  const serviceImage = () => {
    if (windowWidth > 600) {
      return data.serviceImage.childImageSharp.fluid
    } else {
      return data.serviceImageMobile.childImageSharp.fluid
    }
  }

  return (
    <Layout id="innovation-page">
      <SEO title="Innovation" description={dato.heroBody} />
      <ImageHero
        fluid={data.hero.childImageSharp.fluid}
        alt="INW Liquid Products and Filling Capabilities"
      >
        <h1>{dato.heroHeadline}</h1>
        <div
          className="lede"
          dangerouslySetInnerHTML={getHtml(dato.heroBodyNode)}
        />
      </ImageHero>
      <section id="innovation-section">
        <div className="intro">
          <h2>{dato.innovationHeadline}</h2>
          <div
            className="lede"
            dangerouslySetInnerHTML={getHtml(dato.innovationBodyNode)}
          />
        </div>
        <div className="innovation-phases">
          <div className="innovation-phase">
            <h3>{dato.discoveryHeadline}</h3>
            <div
              className="lede"
              dangerouslySetInnerHTML={getHtml(dato.discoveryBodyNode)}
            />
            <div className="innovation-list">
              {dato.discoveryList.map(data => (
                <IconBlock data={data} key={data.id} />
              ))}
            </div>
          </div>
          <div className="innovation-phase">
            <h3>{dato.researchHeadline}</h3>
            <div
              className="lede"
              dangerouslySetInnerHTML={getHtml(dato.researchBodyNode)}
            />
            <div className="innovation-list">
              {dato.researchList.map(data => (
                <IconBlock data={data} key={data.id} />
              ))}
            </div>
          </div>
          <div className="innovation-phase">
            <h3>{dato.developmentHeadline}</h3>
            <div
              className="lede"
              dangerouslySetInnerHTML={getHtml(
                dato.developmentBodyNode
              )}
            />
            <div className="innovation-list">
              {dato.developmentList.map(data => (
                <IconBlock data={data} key={data.id} />
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* <section id="science-section">
        <div className="intro">
          <h2>{dato.scienceHeadline}</h2>
          <div className="body" dangerouslySetInnerHTML={getHtml(dato.scienceBodyNode)} />
        </div>
        <div id="insights">
          <nav>
            <h3>Latest Insights</h3>
            <Link to="/insights">
              <h5>View All</h5>
            </Link>
          </nav>
          <div className="posts">
            {data.allDatoCmsInsightPost.nodes.map(post => (
              <Link
                className="post"
                to={linkShapes.insightPost(post.title)}
                key={post.id}
              >
                <div className="thumbnail">
                  <Img sizes={{ ...post.thumbnail.fluid, aspectRatio: 16 / 9 }} />
                </div>
                <h4>{post.title}</h4>
              </Link>
            ))}
          </div>
        </div>
      </section> */}
      <section id="service-section">
        <div className="image">
          <Parallax id="product-image" factor={0.05}>
            <Img fluid={serviceImage()} alt="" />
          </Parallax>
        </div>{' '}
        <div className="intro">
          <h2>{dato.serviceHeadline}</h2>
          <div
            className="body"
            dangerouslySetInnerHTML={getHtml(dato.serviceBodyNode)}
          />
        </div>
      </section>
    </Layout>
  )
}

export default InnovationPage
